<template>
  <div 
    class="screen-background"
    :style="{backgroundImage: `url(${requireFromAssetsIfLocalSrc(backgroundImageURL)})`}"></div>
</template>

<script>

export default {
  name: "large-screen-background",
  components: {

  },
  data: function () {
      return {
          backgroundImageURL: null
      }
  },
  sockets: {
      changeBackground (data) {
          this.backgroundImageURL = data.backgroundImageURL;
      }
  }
};

</script>

<style scoped>

.screen-background {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

</style>